import GlobalLayout from "components/page/GlobalLayout";
import LicenseComparisonPage from "components/pricing/LicenseComparisonPage";
import React from "react";
import Helmet from "react-helmet";
import { graphql, StaticQuery } from "gatsby";

export default function AudioLicenseComparison() {
  const licenseComparisonData = [
    {
      title: "AI",
      items: [
        {
          text: "Loud noise",
          p: true,
          e: true,
        },
        {
          text: "Carbon monoxide audible alarm detection",
          p: false,
          e: true,
        },
        {
          text: "Smoke audible alarm detection",
          p: false,
          e: true,
        },
        {
          text: "Glass break detection",
          p: false,
          e: true,
        },
      ],
    },
    {
      title: "Storage",
      items: [
        {
          text: "Locally on audio gateway for 90 days",
          p: true,
          e: true,
        },
        {
          text: "30 day cloud archiving",
          p: false,
          e: true,
        },
        {
          text: "Cloud archiving (60, 90 180+ days)",
          p: "$",
          e: "$",
        },
      ],
    },
    {
      title: "Audio",
      items: [
        {
          text: "Real-time streaming (less than 100 ms latency)",
          p: true,
          e: true,
        },
        {
          text: "Listen to live streams and past footage",
          p: true,
          e: true,
        },
        {
          text: "Associate audio with multiple cameras",
          p: true,
          e: true,
        },
        {
          text: "Save and share unlimited audio or audio + video clips",
          p: true,
          e: true,
        },
        {
          text: "2 years of cloud archiving for saved clips",
          p: true,
          e: true,
        },
        {
          text: "Shareable live streams with audio history",
          p: true,
          e: true,
        },
        {
          text: "2 way audio (full duplex with acoustic echo cancellation)",
          p: false,
          e: true,
        },
        {
          text: "Playback audio via internal speaker",
          p: false,
          e: true,
        },
        {
          text: "Playback audio via external speaker",
          p: false,
          e: true,
        },
      ],
    },
    {
      title: "Management",
      items: [
        {
          text: "Unlimited locations",
          p: true,
          e: true,
        },
        {
          text: "Unlimited users",
          p: true,
          e: true,
        },
        {
          text: "Role management",
          p: true,
          e: true,
        },
        {
          text: "Reporting",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Audio Search",
      items: [
        {
          text: "Activity/event indexing for fast searching",
          p: true,
          e: true,
        },
        {
          text:
            "Investigations (search across multiple audio gateways at once)",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Security & Authentication",
      items: [
        {
          text: "Audio is AES-256 encrypted and streamed with SSL TLS v1.2",
          p: true,
          e: true,
        },
        {
          text: "Activity audit logs",
          p: true,
          e: true,
        },
        {
          text: "Anomalous login detection",
          p: true,
          e: true,
        },
        {
          text: "Automatic firmware updates and security patches",
          p: true,
          e: true,
        },
        {
          text: "Single sign-on (SSO) via SAML",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Notifications",
      items: [
        {
          text: "Create custom policies and event notifications",
          p: true,
          e: true,
        },
        {
          text:
            "Create activity regions to only notify for motion in certain areas",
          p: true,
          e: true,
        },
        {
          text: "Custom smart alerts for motion, people, vehicles, and more",
          p: true,
          e: true,
        },
        {
          text: "Alert delivery via email, mobile apps, SMS, Slack, and more",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Integrations",
      items: [
        {
          text:
            "Microsoft Teams, Google Workspace, Okta, Slack, Kisi, and many more",
          p: true,
          e: true,
        },
        {
          text: "API access for reporting and video data",
          p: true,
          e: true,
        },
      ],
    },
    {
      title: "Support",
      items: [
        {
          text: "Email support",
          p: 24,
          e: 24,
        },
        {
          text: "Phone support",
          p: 24,
          e: 24,
        },
      ],
    },
  ];
  const renderContent = data => {
    return (
      <GlobalLayout>
        <Helmet>
          <title>Rhombus Audio License Comparison - A100 Audio Features</title>
          <meta
            name="description"
            content="Rhombus audio gateway license and feature comparison. Learn about the unique differences between Rhombus audio security licenses."
          />
        </Helmet>
        <LicenseComparisonPage
          title1={"Audio License"}
          title2={"Comparison"}
          data={licenseComparisonData}
          headerImage={data.licenseBreakdown}
          activeTab={"audio"}
        />
      </GlobalLayout>
    );
  };

  const GET_IMAGES = graphql`
    query {
      licenseBreakdown: file(
        relativePath: {
          eq: "components/pricing/img/a100-license-breakdown-min.png"
        }
      ) {
        childImageSharp {
          gatsbyImageData(quality: 100, placeholder: NONE)
        }
      }
    }
  `;
  return <StaticQuery query={GET_IMAGES} render={renderContent} />;
}
